import { Box, styled, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { Fragment, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { colors } from '../styles/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { transientOptions } from '../utils/transitient-utils';
import { content } from '../content/content';
import { isSmallScreen } from '../utils/style-utils';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { runtimeEnvIsNode } from '../utils/runtime';
import { isHomepage } from '../utils/path-utils';

const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'white',
}));

type NavItemProps = {
  $secondary?: boolean;
};

const NavItem = styled(
  Box,
  transientOptions
)<NavItemProps>(({ theme, $secondary }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  textAlign: 'center',
  position: 'relative',
  textTransform: 'uppercase',
  paddingTop: $secondary ? 0 : theme.spacing(1),
  paddingBottom: $secondary ? 0 : theme.spacing(1),
  paddingLeft: $secondary ? theme.spacing(2) : 0,
  paddingRight: $secondary ? theme.spacing(2) : 0,
  height: $secondary ? theme.spacing(6) : 'none',
  display: $secondary ? 'flex' : 'unset',
  alignItems: $secondary ? 'center' : 'none',
  justifyContent: $secondary ? 'center' : 'none',
  border: $secondary ? `1px solid ${colors.secondaryColor}` : 'none',
  '&:hover': {
    color: $secondary ? colors.secondaryColor : 'none',
    backgroundColor: $secondary ? 'white' : 'none',
  },
}));

type DropDownContainerProps = {
  $isMenuOpen: boolean;
  $itemCount: number;
};

const DropDownContainer = styled(
  Box,
  transientOptions
)<DropDownContainerProps>(({ $isMenuOpen, $itemCount, theme }) => ({
  whiteSpace: 'nowrap',
  maxHeight: $isMenuOpen ? `calc(${$itemCount} * ${theme.spacing(6)})` : 0,
  position: 'absolute',
  transform: `translate(-50%, calc(${theme.spacing(2)} - 1px))`,
  left: '50%',
  zIndex: 101,
  backgroundColor: colors.secondaryColor,
  overflow: 'clip',
  transition: 'max-height 0.25s ease-in-out',
}));

type ArrowIconProps = {
  $isMenuOpen: boolean;
};

const ArrowIcon = styled(
  KeyboardArrowDownIcon,
  transientOptions
)<ArrowIconProps>(({ $isMenuOpen }) => ({
  rotate: $isMenuOpen ? '180deg' : 'none',
  transition: 'rotate 0.25s ease-in-out',
}));

const BurgerMenuIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  top: theme.spacing(1.5),
  right: theme.spacing(2),
  zIndex: 1001,
}));

type MobileNavWrapperProps = {
  $isMenuOpen: boolean;
};

const MobileNavWrapper = styled(
  Box,
  transientOptions
)<MobileNavWrapperProps>(({ $isMenuOpen, theme }) => ({
  backgroundColor: colors.secondaryColor,
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  width: '100%',
  height: '100vh',
  left: $isMenuOpen ? 0 : '100%',
  top: 0,
  zIndex: 101,
  overflowY: 'auto',
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(6),
  transition: 'left 0.25s ease-in-out',
}));

type MobileNavItemWrapperProps = {
  $secondary?: boolean;
};

const MobileNavItemWrapper = styled(
  Box,
  transientOptions
)<MobileNavItemWrapperProps>(({ $secondary, theme }) => ({
  paddingLeft: $secondary ? theme.spacing(2) : 0,
  paddingBottom: $secondary ? theme.spacing(1.5) : theme.spacing(2),
  userSelect: 'none',
  display: 'flex',
}));

const StyledTypography = styled(Typography)(({}) => ({
  '&:hover': {
    color: colors.lightGrey,
  },
}));

type MobileNavItemProps = {
  to?: string;
  onClick?: () => void;
  text: string | null | undefined;
  secondary?: boolean;
};

const MobileNavItem = ({ to, onClick, text, secondary }: MobileNavItemProps) => (
  <MobileNavItemWrapper $secondary={secondary}>
    <Box sx={{ display: 'flex', position: 'relative' }}>
      {to ? (
        <StyledLink to={to} onClick={onClick}>
          <StyledTypography variant={secondary ? 'h3' : 'h2'}>{text}</StyledTypography>
        </StyledLink>
      ) : (
        <StyledTypography variant={secondary ? 'h3' : 'h2'}>{text}</StyledTypography>
      )}
    </Box>
  </MobileNavItemWrapper>
);

const MobileNavListWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const Navigation: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [activeDropdown, setActiveDropdown] = useState<string>();
  const isMobile = isSmallScreen();

  const handleMenuItemClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMouseOverAndOutEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.type === 'mouseleave') {
      setActiveDropdown(undefined);
    } else {
      setActiveDropdown(e.currentTarget.id);
    }
  };

  useEffect(() => {
    if (!runtimeEnvIsNode) {
      if (isMobile && isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isMobile, isMenuOpen]);

  const navData = useStaticQuery<Queries.GetNavigationItemsQuery>(graphql`
    query GetNavigationItems {
      allMagazines {
        nodes {
          name
          gatsbyPath(filePath: "/{Magazines.name}")
          itemId
        }
      }
      allCategories {
        nodes {
          categoryTitle
          gatsbyPath(filePath: "/kategoria/{Categories.categoryTitle}")
        }
      }
      allContentPages {
        nodes {
          title
          gatsbyPath(filePath: "/{ContentPages.title}")
        }
      }
      allCampaigns {
        group(field: { magazineId: SELECT }) {
          nodes {
            magazineId
          }
          totalCount
        }
      }
    }
  `);

  const magazineIdsSortedByTotalCountOfCampaigns = [...navData.allCampaigns.group].sort((a, b) => (a.totalCount < b.totalCount ? 1 : -1));
  const navMagazines = magazineIdsSortedByTotalCountOfCampaigns
    .map((sortedMagazine) => navData.allMagazines.nodes.find((magazine) => magazine.itemId === sortedMagazine.nodes[0].magazineId))
    .slice(0, 10);

  return (
    <Fragment>
      {/* Mobile navigation BEGIN */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <BurgerMenuIcon onClick={handleMenuItemClick}>
          {isMenuOpen ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
        </BurgerMenuIcon>
        <MobileNavWrapper $isMenuOpen={isMenuOpen}>
          <Box>
            <MobileNavItem to={'/'} onClick={handleMenuItemClick} text={content.components.navigation.home} />
            <MobileNavItem text={content.components.navigation.magazines} />
            <MobileNavListWrapper>
              {navMagazines.map((node, i) => (
                <MobileNavItem secondary to={node?.gatsbyPath!} key={i} onClick={handleMenuItemClick} text={node?.name} />
              ))}
              <MobileNavItem
                secondary
                to={'/lehdet'}
                onClick={handleMenuItemClick}
                text={`>> ${content.components.navigation.showAllMagazines} <<`}
              />
            </MobileNavListWrapper>
            <MobileNavItem text={content.components.navigation.categories} />
            <MobileNavListWrapper>
              {navData.allCategories.nodes.map((node, i) => (
                <MobileNavItem secondary to={node?.gatsbyPath!} key={i} onClick={handleMenuItemClick} text={node?.categoryTitle} />
              ))}
            </MobileNavListWrapper>
            {navData.allContentPages.nodes.map(
              (node, i) =>
                !isHomepage(node.gatsbyPath!) && (
                  <MobileNavItem to={node?.gatsbyPath!} key={i} onClick={handleMenuItemClick} text={node?.title} />
                )
            )}
          </Box>
        </MobileNavWrapper>
      </Box>
      {/* Mobile navigation END */}
      {/* Desktop navigation BEGIN */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid2 container columnSpacing={4}>
          <Grid2
            id={'magazineDropdown'}
            onMouseEnter={(e) => handleMouseOverAndOutEvent(e)}
            onMouseLeave={(e) => handleMouseOverAndOutEvent(e)}
            pt={2}
            pb={2}
          >
            <NavItem>
              <Box sx={{ display: 'flex' }}>
                <StyledTypography>{content.components.navigation.magazines}</StyledTypography>
                <ArrowIcon $isMenuOpen={isMenuOpen} />
              </Box>
              <DropDownContainer $isMenuOpen={activeDropdown === 'magazineDropdown'} $itemCount={navMagazines.length + 1}>
                {navMagazines.map((node, i) => (
                  <StyledLink to={node?.gatsbyPath!} key={i}>
                    <NavItem $secondary>
                      <Typography>{node?.name}</Typography>
                    </NavItem>
                  </StyledLink>
                ))}
                <StyledLink to={'/lehdet'} key={content.components.navigation.showAllMagazines}>
                  <NavItem $secondary>
                    <Typography>{`>> ${content.components.navigation.showAllMagazines} <<`}</Typography>
                  </NavItem>
                </StyledLink>
              </DropDownContainer>
            </NavItem>
          </Grid2>
          <Grid2
            id={'categoryDropdown'}
            onMouseEnter={(e) => handleMouseOverAndOutEvent(e)}
            onMouseLeave={(e) => handleMouseOverAndOutEvent(e)}
            pt={2}
            pb={2}
          >
            <NavItem>
              <Box sx={{ display: 'flex' }}>
                <StyledTypography>{content.components.navigation.categories}</StyledTypography>
                <ArrowIcon $isMenuOpen={isMenuOpen} />
              </Box>
              <DropDownContainer $isMenuOpen={activeDropdown === 'categoryDropdown'} $itemCount={navMagazines.length}>
                {navData.allCategories.nodes.map((node, i) => (
                  <StyledLink to={node?.gatsbyPath!} key={i}>
                    <NavItem $secondary>
                      <Typography>{node?.categoryTitle}</Typography>
                    </NavItem>
                  </StyledLink>
                ))}
              </DropDownContainer>
            </NavItem>
          </Grid2>
          {navData.allContentPages.nodes.map(
            (node, i) =>
              !isHomepage(node.gatsbyPath!) && (
                <Grid2 key={i} display={'flex'} alignItems={'center'}>
                  <StyledLink to={node?.gatsbyPath!}>
                    <NavItem>
                      <StyledTypography>{node?.title}</StyledTypography>
                    </NavItem>
                  </StyledLink>
                </Grid2>
              )
          )}
        </Grid2>
      </Box>
      {/* Desktop navigation END */}
    </Fragment>
  );
};

export default Navigation;

