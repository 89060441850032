export const content = {
  pages: {
    magazine: {
      titleSuffix: 'lehtitarjoukset',
      aiDescriptionTitlePrefix: 'Tietoa',
      aiDescriptionTitleSuffix: '-lehdestä',
      seo: {
        titleSuffix: 'lehtitarjoukset',
        descriptionPrefix: 'Netin parhaan lehtitarjoukset. Klikkaa ja katso ajankohtaiset',
        descriptionSuffix: 'tarjoukset!',
      },
    },
    category: {
      titleSuffix: '-lehdet lehtitarjoukset',
      aiDescriptionTitlePrefix: 'Suosituimmat',
      aiDescriptionTitleSuffix: '-lehdet',
      magazineListTitlePrefix: 'Suomen suosituimmat',
      magazineListTitleSuffix: '-lehdet',
      imageAltSuffix: '- lehdet lehtitarjoukset',
      seo: {
        titleSuffix: '-lehdet lehtitarjoukset. Tutustu ja tilaa!',
        descriptionPrefix: 'Suomen suosituimmat',
        descriptionSuffix: ' -lehdet lehtitarjoukset. Tutustu ja hyödynnä tarjoushinnat!',
      },
    },
    allMagazines: {
      title: 'Kaikki lehdet',
    },
  },
  components: {
    campaignList: {
      title: 'Aktiiviset tarjoukset',
    },
    campaignCard: {
      imageAltSuffix: 'lehtitarjous',
      CTA: 'Avaa tarjous!',
      readMore: 'Lue lisää!',
    },
    magazineCard: {
      CTA: 'Katso tarjoukset!',
      imageAltSuffix: '-lehti',
    },
    navigation: {
      home: 'Etusivu',
      magazines: 'Suosituimmat lehdet',
      showAllMagazines: 'Näytä kaikki',
      categories: 'Kategoriat',
    },
  },
};

