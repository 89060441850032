import { Box, Container } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import Header from '../components/Header';
import '../../styles.css';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ overflowX: 'hidden', position: 'relative' }}>
      <Header />
      <Container maxWidth={'lg'}>
        <Box pb={3} pt={3}>
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default Layout;

