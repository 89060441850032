import { Box, Container } from '@mui/material';
import * as React from 'react';
import { colors } from '../styles/colors';
import Navigation from './Navigation';
import logo from '../images/logo.svg';
import { Link } from 'gatsby';

const Header: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: colors.primaryColor }}>
      <Container maxWidth={'lg'} sx={{ display: 'flex' }}>
        <Link to={'/'} style={{ display: 'flex' }}>
          <Box component={'img'} src={logo} alt={'Aikakausilehdet'} sx={{ width: { xs: '250px', md: '350px' }, pt: 1.5, pb: 1.5 }} />
        </Link>
      </Container>
      <Box sx={{ backgroundColor: colors.secondaryColor, color: 'white' }}>
        <Container maxWidth={'lg'}>
          <Navigation />
        </Container>
      </Box>
    </Box>
  );
};

export default Header;

